<template>
  <div class="text-center">
    <v-dialog v-model="dialog" v-if="!isMobile">
      <v-card>
        <v-img
          src="https://www.childprotection.gov.hk/wp/wp-content/uploads/2024/04/321-seminar_web-app_website_banner.jpg"
          contain
          style="position: relative"
          class="d-flex justify-center"
        > 
          <v-btn
            style="position: absolute; right: 20px; top: 20px"
            icon
            @click="dialog = false"
            aria-label="close"
          >
            <v-icon large color="white">mdi-close</v-icon>
          </v-btn>

          <a
            :href="
              isEn
                ? 'https://www.idea.gov.hk/hkpf-crm-sup/multi-disciplinary-seminar-on-child-sexual-abuse-in-the-cyber-world/en'
                : 'https://www.idea.gov.hk/hkpf-crm-sup/multi-disciplinary-seminar-on-child-sexual-abuse-in-the-cyber-world/tc'
            "
            target="_blank"
            aria-label="link"
          >
            <v-img
              src="https://www.childprotection.gov.hk/wp/wp-content/uploads/2024/04/4Web-App-Click-button.png"
              width="290px"
              style="margin: auto; top: 70%"
            >
            </v-img>
          </a>
        </v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" v-if="isMobile" width="unset">
      <v-card>
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters align="center" justify="center">
              <v-img
                src="https://www.childprotection.gov.hk/wp/wp-content/uploads/2024/04/Web-App_321-seminar_mobile_v2.jpg"
                style="position: relative"
              >
                <v-btn
                  style="position: absolute; right: 20px; top: 20px"
                  icon
                  @click="dialog = false"
                  aria-label="button"
                >
                  <v-icon large color="white">mdi-close</v-icon>
                </v-btn>

                <a
                  :href="
                    isEn
                      ? 'https://www.idea.gov.hk/hkpf-crm-sup/multi-disciplinary-seminar-on-child-sexual-abuse-in-the-cyber-world/en'
                      : 'https://www.idea.gov.hk/hkpf-crm-sup/multi-disciplinary-seminar-on-child-sexual-abuse-in-the-cyber-world/tc'
                  "
                  target="_blank"
                  style="position: absolute; top: 54%; left: 50%; transform: translate(-50%, -50%);"
                  aria-label="link"
                >
                  <v-img
                    src="https://www.childprotection.gov.hk/wp/wp-content/uploads/2024/03/4Web-App-Click-button.png"
                    min-width="200px"
                    max-width="220px"
                  >
                  </v-img>
                </a>
              </v-img>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "HomePopup",
  data() {
    return {
      dialog: true,
    };
  },
  props: {
    isMobile: { type: Boolean, default: false },
  },
  computed: {
    isEn() {
      return this.$i18n.locale == "en";
    },
  },
};
</script>

<style lang="scss" scoped></style>
